:root {
    --blue: #0E65D9;
    --red: #FF6868;
    --light-gray: #E2E2E2;
    --gray: #EFF1F3;
    --semi-gray: #C4C4C4;
    --input-gray: #D9DADE;
    --placeholder-gray: #CACBCC;
    --dark-gray: #394149;
    --dark: #12171D;
    --dark-btn: #202428;
    --black: #000;
    --transition-for-line: 0.5s;
}