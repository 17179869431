.ourClients {
    display: flex;
    justify-content: space-between;
    min-height: 936px;
    background-color: var(--dark);
    font-family: 'Manrope', sans-serif;
    color: #fff;

    .ourClients__wrap.txt {
        width: 35%;
        padding: 80px 0 0 9.27%;

        .ourClients__h1 {
            width: min-content;
            margin: 0 0 35px;
            font-family: 'Krona One', sans-serif;
            font-weight: 400;
            font-size: 7.4rem;
            font-size: max(7.4rem, 34px);
            line-height: 9.2rem;
            line-height: max(9.2rem, 42px);
        }

        .ourClients__p {
            margin: 0 0 75px;
            padding: 0 10px 0 0;
            font-weight: 600;
            font-size: 24px;
            font-size: max(2.4rem, 18px);
            line-height: 3.3rem;
            line-height: max(3.3rem, 24px);
        }

        .ourClients__wrap-countries {
            height: 285px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: space-between;

            .ourClients__country {
                width: 34%;
                padding: 0 0 30px;
                margin: 0;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    // .ourClients__wrap.globe {
    //     width: 58%;
    //     position: relative;
    // }
}

// media query:
@media only screen and (max-width: 1000px) {
    .ourClients {
        .ourClients__wrap.txt {
            width: 50%;
            padding: 80px 4%;
            padding: 80px max(4%, 25px);
        }
        .ourClients__wrap.globe {
            width: 67%;
        }
    }
}

// mobile devices:
@media only screen and (max-width: 768px) {
    .ourClients {
        flex-direction: column;
        // display: flex;
        // justify-content: space-between;
        // min-height: 936px;
        // background-color: var(--dark);
        // font-family: 'Manrope', sans-serif;
        // color: #fff;

        .ourClients__wrap.txt {
            width: 90%;
            // padding: 80px 0 0 9.27%;

            // .ourClients__h1 {
            //     width: min-content;
            //     margin: 0 0 35px;
            //     font-family: 'Krona One', sans-serif;
            //     font-weight: 400;
            //     font-size: 7.4rem;
            //     font-size: max(7.4rem, 34px);
            //     line-height: 9.2rem;
            //     line-height: max(9.2rem, 42px);
            // }

            .ourClients__p {
            //     margin: 0 0 75px;
            //     padding: 0 10px 0 0;
            //     font-weight: 600;
            //     font-size: 24px;
            //     line-height: 33px;
            }

            // .ourClients__wrap-countries {
            //     height: 285px;
            //     display: flex;
            //     flex-direction: column;
            //     flex-wrap: wrap;
            //     align-content: space-between;

            // .ourClients__country {
            //     width: 34%;
            //     padding: 0 0 30px;
            //     margin: 0;
            //     font-weight: 700;
            //     font-size: 20px;
            //     line-height: 27px;
            // }
            // }
        }

        .ourClients__wrap.globe {
            // width: 58%;
            top:0;
            transform: translate(-30%,-25%) scale(0.7);
            position: relative;
        }
    }
}