.OurService {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 129.2rem;
  font-family: "Manrope", sans-serif;
  color: var(--dark-btn);

  .OurService__wrap {
    width: 100%;
    z-index: 20;

    &.slides_initial {
      overflow: hidden;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 768px) {
        min-height: 640px;
        max-height: 700px;
      }
      > div {
        width: 1400px;
        max-height: 500px;

        .OurService__slides {
          display: flex;
          flex-direction: row;
          position: relative;
          width: 100%;
          transition: transform ease-out 0.3s;

          .OurService__slide {
            flex-basis: 100%;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;

            &.active {
              opacity: 1;
            }
          }
        }
      }
    }

    &:nth-child(1) {
      position: relative;
      height: 52.9%;
      padding: 0 9.27%;
      border-bottom: 1px solid var(--gray);

      .OurService__header {
        position: absolute;
        top: 8.2rem;
        left: 9.27%;
        width: fit-content;
        font-family: "Manrope", sans-serif;
        z-index: 2;

        .OurService__h2 {
          margin: 0 0 10px;
          font-weight: 600;
          font-size: 3.4rem;
          line-height: 4.6rem;
        }

        .OurService__h1 {
          margin: 0 0 19px;
          line-height: 10.5rem;
          font-weight: 400;
          font-size: 8.4rem;
          font-family: "Krona One", sans-serif;
        }

        .OurService__txt {
          margin: 0;
          line-height: 3.3rem;
          font-size: 2.4rem;
          font-weight: 400;

          .OurService__span {
            font-weight: 600;
            color: var(--blue);
          }
        }
      }

      .OurService__service-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        .OurService__service {
          position: relative;
          width: 25%;
          height: 100%;
          max-width: 391px;
          border-left: 1px solid var(--gray);
          // transition: 0.5s;

          &:first-child {
            border: none;
          }

          .OurService__p {
            width: min-content;
            margin: 0;
            position: absolute;
            left: 8.2%;
            bottom: 5.1rem;
            font-weight: 400;
            font-size: 2.8rem;
            line-height: 3.8rem;
            text-transform: capitalize;
            color: #0a1a19;
            transition: 0.5s;
          }

          .OurService__info {
            position: absolute;
            left: 8.2%;
            bottom: 5rem;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1.9rem;
            color: var(--blue);
            opacity: 0;
            transition: 0.5s;
          }

          &::before {
            content: "";
            display: block;
            height: 0;
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 95%),
              url(../../../../img/our_service_header.png);
            transition: 0.5s;
          }

          &:nth-child(1)::before {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 95%),
              url(../../../../img/our_services/virtual_reality_www.png);
          }
          &:nth-child(2)::before {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 95%),
              url(../../../../img/our_services/augmentedreality_www.png);
          }
          &:nth-child(3)::before {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 95%),
              url(../../../../img/our_services/mixedreality_www.png);
          }
          &:nth-child(4)::before {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 95%),
              url(../../../../img/our_services/gamedev_www.png);
          }

          &:hover,
          &.active {
            .OurService__p {
              bottom: 12.6rem;
            }

            .OurService__info {
              opacity: 1;
            }

            &::before {
              height: 100%;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      width: 77%;
      height: 47.1%;
      margin: 0 auto;
      padding: 7rem 0;
      font-weight: 500;
      font-size: 3.4rem;
      line-height: 5.8rem;
      text-align: center;
      letter-spacing: 0.02em;

      .OurService__bold {
        font-weight: 700;
      }

      .OurService__underline {
        text-decoration: underline 1px #202428b0;
        line-height: 0px;
      }

      .OurService__p {
        margin: 5rem 0 3.9rem;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.2rem;
      }

      .OurService__blue {
        color: var(--blue);
        text-transform: uppercase;
      }
    }
  }
}

// mobile devices:

@media only screen and (max-width: 768px) {
  .OurService {
    height: 1820px;

    .OurService__wrap {
      &:nth-child(1) {
        height: 70%;
        padding: 0 6.04%;

        .OurService__header {
          position: relative;
          top: 50px;
          left: 0;

          .OurService__h2 {
            font-size: 20px;
            line-height: 27px;
          }

          .OurService__h1 {
            margin: 0 0 15px;
            line-height: 42px;
            font-size: 34px;
          }

          .OurService__txt {
            line-height: 24px;
            font-size: 18px;
          }
        }

        .OurService__service-wrap {
          flex-direction: column;
          top: 230px;

          .OurService__service {
            width: 100%;
            height: 25%;
            max-width: none;
            border-left: none;
            border-bottom: 1px solid var(--gray);

            &:first-child {
              border-top: 1px solid var(--gray);
              border-bottom: 1px solid var(--gray);
            }

            .OurService__p {
              width: fit-content;
              left: 6.04%;
              font-size: 26px;
              line-height: 35px;
            }

            .OurService__info {
              left: 6.04%;
              bottom: 32px;
              font-size: 14px;
              line-height: 19px;
            }

            &::before {
              background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 65.93%),
                url(../../../../img/our_service_header_mobile.png);
              background-repeat: no-repeat;
              background-size: cover;
            }

            &:hover {
              .OurService__p {
                bottom: 60px;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        width: 100%;
        height: 30%;
        padding: 100px 6.04% 0;
        font-size: 20px;
        line-height: 34px;

        .OurService__p {
          margin: 50px 0 50px;
          font-size: 16px;
          line-height: 22px;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}
