.input__wrap {
  width: 48.5%;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  .input__label {
    color: var(--dark-btn);
    padding: 0 0 10px;
  }

  .budget_input_wrapper {
    position: relative;
    .input__input {
      width: 100%;
      padding-left: 25px;
    }

    &::before {
      content: "$";
      text-align: center;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
    }
  }

  .input__input {
    height: 65px;
    padding: 0 12px;
    font-weight: 600;
    color: var(--dark);
    border: 1px solid var(--input-gray);

    &:active,
    &:focus {
      border: 1px solid var(--dark-btn);
      outline: none;
    }

    &::placeholder {
      color: #cacbcc;
    }
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  }
}
