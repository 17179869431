.readMore__wrap {
    position: relative;
    width: 240px;
    max-width: 240px;
    color: #fff;
    background-color: transparent;
    border: none;

    .readMore__txt {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
        margin: 0;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        cursor: pointer;
        z-index: 1;
    }

    .readMore__wrap-play-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 65px;
        // background-color: #FFF;
        border: 1px solid var(--gray);
        border-radius: 50px;
        text-align: right;
        transition: 0.3s;

        .readMore__play-icon {
            max-width: 100%;
            height: auto;
            cursor: pointer;
        }
    }

    &.dark {
        color: var(--dark);
    }

    &:hover {
        .readMore__wrap-play-icon {
            width: 100%;

            .readMore__play-icon {
                max-width: 100%;
                height: auto;
                cursor: pointer;
            }
        }
    }
}
