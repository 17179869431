.headerBlog {
  width: 100%;
  position: relative;
  margin: 132px 0 0;
  min-height: 594px;
  background-color: var(--dark);
  background-image: url("../../img/list_articles_header.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: "Manrope", sans-serif;
  color: #fff;
  z-index: 1;

  // background-size: contain;
  .headerBlog__wrap {
    padding: 91px 0 46px 9.79%;
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding-right: 25px;
      padding-left: 25px;
    }

    .headerBlog__h1 {
      margin: 0;
      padding: 19px 35px 24px 0;
      font-weight: 600;
      font-size: 34px;
      line-height: 48px;
    }

    .headerBlog__description {
      margin: 0;
      padding: 0 20px 35px 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
    }

    @media only screen and (max-width: 768px) {
      .readMore__wrap {
        height: 65px;
        margin-bottom: 1rem;
      }
    }
  }
}
