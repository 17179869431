.select__wrap {
    position: relative;
    width: 48.5%;
    padding: 24px 0 0;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    .select__input {
        padding-right: 20px;
        background-color: transparent;

        height: 65px;
        padding: 0 12px;
        font-weight: 600;
        color: var(--dark);
        border: 1px solid var(--input-gray);

        &::-ms-expand {
            display: none;
        }

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:active,
        &:focus {
            border: 1px solid var(--dark-btn);
            outline: none;
        }
    }

    &::after {
        position: absolute;
        bottom: 0;
        right: 25px;
        transform: translateY(-100%);
        content: url('../../svg/contact_estimate_select_arrow.svg');
        z-index: -1;
    }

    .select__label {
        color: var(--dark-btn);
        padding: 0 0 10px;
    }
}