#cursor-dot,
#cursor-dot-outline {
    z-index: 999;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
    width: 20px;
    height: 20px;
    background-color: var(--blue);
    transition: width 0.4s, height 0.4s;

    &.play {
        background-image: url('../../svg/our_casestudy_play.svg');
        width: 82px;
        height: 82px;
        opacity: 1 !important;
        border: none;
        background-color: transparent;

        ::before {
            width: 82px;
            height: 82px;
            background-color: #fff;
            border-radius: 50px;
            opacity: 1 !important;
        }
    }
}

#cursor-dot-outline {
    width: 40px;
    height: 40px;
    border: 1px solid var(--blue);

    &.play {
        opacity: 0 !important;
    }
}