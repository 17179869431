.sliderIndicators__circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;

    .sliderIndicators__circle-back {
        position: absolute;
        inset: 0;
        color: transparent;
        stroke: currentColor;
        stroke-width: 2;
        fill: none;
    }

    .sliderIndicators__circle-front {
        position: absolute;
        inset: 0;
        color: var(--blue);
        stroke: currentColor;
        stroke-width: 2;
        fill: none;
        transform: rotate(-90deg);
        stroke-dasharray: 157 157;
        stroke-dashoffset: -157;
        transition: stroke-dashoffset ease-in-out 500ms;
    }

    .sliderIndicators__dot {
        display: block;
        width: 9px;
        height: 9px;
        background: rgb(211, 211, 211);
        border-radius: 8px;
    }

    &.active {
        .sliderIndicators__circle-front {
            transition: none;
            stroke-dasharray: 0 157;
            stroke-dashoffset: 0;
            animation: spin 3.5s linear;
            animation-fill-mode: forwards;
        }

        .sliderIndicators__dot {
            width: 5px;
            height: 5px;
            background: var(--blue);
        }
    }
}
