.blogCategory {
  font-family: "Manrope", sans-serif;

  .blogCategory__main {
    position: relative;
    padding: 0 0 110px;
    display: flex;
    flex-direction: column;

    .blogCategory__wrap {
      width: 100%;
      padding: 70px 10% 70px 9.27%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 7rem;

        .button {
          width: 100%;
        }
      }

      .blogCategory__title {
        .blogCategory__p {
          padding: 0 0 11px;
          font-weight: 700;
          font-size: 2.4rem;
          font-size: max(2.4rem, 18px);
          line-height: 4.8rem;
          line-height: max(4.8rem, 25px);
          color: var(--blue);
          @media only screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        .blogCategory__h1 {
          margin: 0;
          padding: 0;
          width: min-content;
          min-height: 184px;
          font-family: "Krona One", sans-serif;
          font-weight: 400;
          font-size: 7.4rem;
          font-size: max(7.4rem, 34px);
          line-height: 9.2rem;
          line-height: max(9.2rem, 42px);
          color: var(--dark);
          @media only screen and (max-width: 768px) {
            font-size: 34px !important;
            line-height: 42px;
            min-height: unset;
          }

          &.small {
            font-size: 60px;
          }
        }
      }
    }

    .blogCategory__articles {
      padding: 0 6.4%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      .articleCard__wrap-article {
        position: relative;
        width: 33.333%;
        height: max-content;
        padding: 32px 2.7% 32px;

        &:nth-child(3n + 2) {
          margin-top: -335px;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: -3;
          transition: 0.3s;
        }

        &:hover {
          &::after {
            background-color: var(--gray);
          }
        }

        .articleCard__title {
          font-size: 24px;
          line-height: 34px;
          margin: 18px 0 24px;
        }

        .articleCard__description {
          font-size: 16px;
          line-height: 28px;
        }

        .articleDate__info.article_in_card {
          margin-top: 21px;
        }

        .readMore__wrap {
          .readMore__wrap-play-icon {
            background-color: #fff;
          }

          // .readMore__txt {
          //   display: none;
          // }
        }
      }
    }

    .blogCategory__line.line {
      left: 35.4%;

      .blogCategory__active-line {
        position: absolute;
        top: 141px;
        left: 0;
        height: 376px;
        width: 1px;
        background-color: var(--blue);
        transition: 0.5s;
      }

      &:nth-child(2n) {
        left: 64.5%;

        .blogCategory__active-line {
          top: 44px;
          height: 193px;
        }
      }
    }
  }
}

// mobile devices:
@media only screen and (max-width: 768px) {
  .blogCategory {
    // font-family: 'Manrope', sans-serif;

    .blogCategory__main {
      // position: relative;
      // padding: 0 0 110px;
      // display: flex;
      // flex-direction: column;

      .blogCategory__wrap {
        // width: 100%;
        // padding: 70px 10% 70px 9.27%;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        .blogCategory__title {
          .blogCategory__p {
            // padding: 0 0 11px;
            // font-weight: 700;
            // font-size: 2.4rem;
            // font-size: max(2.4rem, 18px);
            // line-height: 4.8rem;
            // line-height: max(4.8rem, 25px);
            // color: var(--blue);
          }

          .blogCategory__h1 {
            // margin: 0;
            // padding: 0;
            // width: min-content;
            // min-height: 184px;
            // font-family: 'Krona One', sans-serif;
            // font-weight: 400;
            // font-size: 7.4rem;
            // font-size: max(7.4rem, 34px);
            // line-height: 9.2rem;
            // line-height: max(9.2rem, 42px);
            // color: var(--dark);

            &.small {
              // font-size: 60px;
            }
          }
        }
      }

      .blogCategory__articles {
        flex-direction: column;
        // padding: 0 6.4%;
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;

        .articleCard__wrap-article {
          width: 100%;
          // position: relative;
          // width: 33.333%;
          // height: max-content;
          // padding: 32px 2.7% 32px;

          &:nth-child(3n + 2) {
            margin-top: 0;
            // margin-top: -335px;
          }

          &::after {
            // position: absolute;
            // top: 0;
            // left: 0;
            // display: block;
            // content: '';
            // width: 100%;
            // height: 100%;
            // background-color: transparent;
            // z-index: -3;
            // transition: 0.3s;
          }

          &:hover {
            &::after {
              // background-color: var(--gray);
            }
          }

          .articleCard__title {
            // font-size: 24px;
            // line-height: 34px;
            // margin: 18px 0 24px;
          }

          .articleCard__description {
            // font-size: 16px;
            // line-height: 28px;
          }

          .articleDate__info.article_in_card {
            // margin-top: 21px;
          }

          .readMore__wrap {
            // .readMore__wrap-play-icon {
            //     background-color: #fff;
            // }

            .readMore__txt {
              display: block;
              z-index: 20;
            }
          }

          .articleCard__wrap-txt {
            display: none;
          }
        }
      }

      .blogCategory__line.line {
        display: none;
        // left: 35.4%;

        .blogCategory__active-line {
          // position: absolute;
          // top: 141px;
          // left: 0;
          // height: 376px;
          // width: 1px;
          // background-color: var(--blue);
          // transition: 0.5s;
        }

        &:nth-child(2n) {
          // left: 64.5%;

          .blogCategory__active-line {
            // top: 44px;
            // height: 193px;
          }
        }
      }
    }
  }
}
