.carouselItem.active {
  display: block;
}

.carouselItem {
  display: none;

  .carouselItem__bgc-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 29.58%;
    // height: 0;
    height: 100%;
    background-color: var(--dark);
    animation: bgc-right 1s linear both;

    &.active {
      // animation: bgc-right-back 1s linear both 1.5s;
    }
  }

  .carouselItem__wrap-ellipse {
    position: absolute;
    // bottom: 32.77%;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) scale(0);
    // width: 329px;
    // height: 329px;
    width: 32.9rem;
    height: 32.9rem;
    border-radius: 50%;
    background-color: var(--blue);
    animation: ellipse 0.8s linear 3s both;

    &.active {
      animation: ellipse-back 0.8s linear;
    }
  }

  .carouselItem__next {
    // padding: 20px 0 0;
    padding: 4rem 0 0;
    margin: 0;
    display: flex;
    position: absolute;
    top: 50%;
    right: 0;
    height: fit-content;
    width: fit-content;
    transform: translate(-50%, -50%) scale(1);
    transition: 0.3s;
    background-color: transparent;
    border: none;
    overflow: hidden;

    .carouselItem__span {
      margin-right: 5px;
      height: fit-content;
      width: fit-content;
      transform: translate(0%, 80%);
      font-family: "Manrope", sans-serif;
      font-weight: 600;
      // font-size: 14px;
      font-size: 1.4rem;
      // line-height: 19px;
      line-height: 1.9rem;
      color: #fff;
      animation: next-txt 0.2s linear 2.8s both, float 6s ease-in-out 4s infinite;

      &.active {
        animation: next-txt-back 0.2s linear 0.6s both;
      }

      &:nth-last-of-type(1) {
        animation: next-txt 0.2s linear 2.9s both, float 6s ease-in-out 4s infinite;

        &.active {
          animation: next-txt-back 0.2s linear 0.5s both;
        }
      }
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  .carouselItem__wrap-human {
    position: absolute;
    top: 0;
    right: 11.72%;
    width: 42.19%;
    // transform: scale(1.3);
    transform: scale(1);
    height: 100%;
    overflow: hidden;
    opacity: 0;
    animation: human 0.8s linear 0.2s both, opacity 0.2s linear forwards;

    &.active {
      // animation: human-back 0.8s linear 2s both, opacity-back 0.4s linear 2s backwards;
    }

    .carouselItem__human {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      height: auto;
    }
  }

  .carouselItem__wrap-rock {
    position: absolute;
    bottom: 51.86%;
    right: 37.29%;
    width: 16.25%;
    transform: scale(0) rotate(-90deg) translateY(0);
    animation: rock 0.8s linear 0.1s, float 6s ease-in-out infinite 0.9s;

    &.active {
      animation: float 6s ease-in-out infinite alternate, rock-back 0.8s linear both;
    }

    .carouselItem__rock {
      max-width: 100%;
      height: auto;
    }
  }

  .carouselItem__wrap-txt {
    position: absolute;
    top: 29.6%;
    left: 9.27%;
    // width: 33.44%;
    width: 45%;
    color: var(--dark-btn);
    font-family: "Manrope", sans-serif;

    .carouselItem__h2 {
      // padding-bottom: 30px;
      padding-bottom: 3rem;
      font-weight: 400;
      // font-size: 24px;
      font-size: 2.4rem;
      // line-height: 33px;
      line-height: 3.3rem;
      opacity: 0;
      animation: opacity 0.8s linear 0.1s both;

      &.active {
        animation: opacity-back 0.8s linear 2s backwards;
      }

      &.span {
        opacity: 1;
        color: var(--blue);
      }
    }

    .carouselItem__h1 {
      position: relative;
      margin: 0;
      font-weight: 700;
      // font-size: 40px;
      // font-size: 3.9vmin;
      font-size: 4rem;
      // line-height: 55px;
      // line-height: 5.4vmin;
      line-height: 5.5rem;
      overflow: hidden;

      .carouselItem__h1-span {
        position: relative;
        top: 90px;
        animation: move-to-top 0.3s linear both;

        &.active {
          animation: move-to-top-back 0.3s linear both;
        }
      }
    }

    .carouselItem__wrap-p {
      display: flex;
      position: relative;
      overflow: hidden;

      .carouselItem__p {
        position: relative;
        top: 45px;
        opacity: 0;
        margin: 0;
        // padding: 30px 5px 0 0;
        padding: 3rem 0.5rem 0 0;
        font-weight: 500;
        // font-size: 18px;
        font-size: 1.8rem;
        // line-height: 30px;
        line-height: 3rem;
        animation: move-to-top 0.4s linear both, opacity 0.6s linear both;

        &.active {
          animation: move-to-top-back 0.4s linear both, opacity-back 0.6s linear 3.4s backwards;
        }
      }

      &:nth-last-of-type(1) {
        .carouselItem__p {
          padding: 0 5px 43px 0;
          padding: 0 0.5rem 4.3rem 0;
        }
      }
    }

    .button.animation {
      font-size: 1.6rem;
      padding: 1.6rem 3.2rem;
      animation: opacity 1s linear 2.2s both;
      opacity: 0;

      &.active {
        animation: opacity-back 1s linear 1.8s both;
      }
    }
  }
}

// media query:

// mobile devices:

@media only screen and (max-width: 768px) {
  .carouselItem {
    .carouselItem__bgc-right {
      top: 50%;
      width: 50%;

      // &.active {
      //   animation: bgc-right-back 1s linear both 1.5s;
      // }
    }

    .carouselItem__wrap-ellipse {
      display: none;
    }

    .carouselItem__next {
      display: none;
    }

    .carouselItem__wrap-human {
      top: 50%;
      right: 0;
      height: 50%;
      width: 100%;
      // animation: human 0.8s linear 0.2s both, opacity 0.2s linear forwards;

      // &.active {
      //   animation: human-back 0.8s linear 2s both, opacity-back 0.4s linear 2s backwards;
      // }

      .carouselItem__human {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        max-height: 100%;
        width: auto;
      }
    }

    .carouselItem__wrap-rock {
      // position: absolute;
      // top: 50%;
      bottom: 30%;
      right: 60%;
      width: 25.25%;
      transform: scale(0) rotate(-90deg) translateY(0);
      // animation: rock 0.8s linear 0.1s, float 6s ease-in-out infinite 0.9s;

      // &.active {
      //   animation: float 6s ease-in-out infinite alternate, rock-back 0.8s linear both;
      // }

      .carouselItem__rock {
        max-width: 100%;
        height: auto;
      }
    }

    .carouselItem__wrap-txt {
      box-sizing: border-box;
      top: 16.76%;
      padding: 0 6.04%;
      left: 0;
      // left: 6.04%;
      width: 100%;
      // height: 55%;

      .carouselItem__h2 {
        margin: 0;
        padding-bottom: 6.1rem;
        font-size: 3.7rem;
        line-height: 5rem;
      }

      .carouselItem__h1 {
        margin: 0;
        font-size: 5.75rem;
        line-height: 7.8rem;

        // .carouselItem__h1-span {
        //   position: relative;
        //   top: 45px;
        //   animation: move-to-top 0.3s linear both;

        //   &.active {
        //     animation: move-to-top-back 0.3s linear both;
        //   }
        // }
      }

      .carouselItem__wrap-p {
        display: none;
      }

      .button.animation {
        margin-top: 6.1rem;
        font-size: 3.3rem;
        padding: 3.3rem 6.9rem;
        // animation: opacity 1s linear 2.2s both;

        // &.active {
        //   animation: opacity-back 1s linear 1.8s both;
        // }
      }
    }
  }
}
