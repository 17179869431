.blogArticle {
  position: relative;
  .headerBlack {
    padding-left: 9.27%;
    padding-right: 9.27%;
    @media only screen and (max-width: 768px) {
      padding-left: 6%;
      padding-right: 6%;
    }
    display: block;
    @media only screen and (min-width: 769px) {
      padding-bottom: 306px;
    }

    .headerBlack__wrap-title {
      padding: 170px 0 50px;
      display: block;

      > h1 {
        text-align: left;
        @media only screen and (min-width: 769px) {
          max-width: 65%;
        }
        @media only screen and (max-width: 768px) {
          font-size: 28px;
          line-height: 33px;
          padding: 2rem 0px 3rem;
        }
      }

      .category_and_date {
        font-size: 18px;
        line-height: 25px;
        margin-top: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          row-gap: 10rem;
        }

        > div:first-child {
          .articleDate__service {
            // line-height: 25px;
            color: var(--blue);
          }

          .articleDate__dot {
            background-color: var(--blue);
            margin: 0 14px;
            display: inline-block;
            border-radius: 50%;
            width: 4px;
            height: 4px;
            vertical-align: middle;
          }

          .articleDate__date {
            color: white;
          }
        }

        > div:last-child {
          @media only screen and (min-width: 769px) {
            margin-right: 15rem;
          }
          .articleCard__wrap-author {
            display: flex;
            align-items: center;

            .articleCard__wrap-avatar {
              margin: 0 24px 0 0;
              position: relative;

              img {
                max-width: 100%;
                height: auto;
                position: relative;
                z-index: 1;
              }

              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(0, -50%);
                content: "";
                width: 75%;
                height: 63px;
                background-color: var(--blue);
                z-index: 0;
              }
            }

            .articleCard__wrap-txt {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .articleCard__name {
                margin: 0 0 3px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
              }

              .articleCard__profession {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #b0b0b0;
              }
            }
          }
        }
      }
    }
  }

  .headerImage__wrap {
    @media only screen and (min-width: 769px) {
      margin-top: -256px;
      padding: 0 5%;
    }
    margin-bottom: 5rem;

    > img {
      width: 100%;
      position: relative;
      border-radius: 8px;
      height: auto;
      aspect-ratio: 15 / 4;
      object-fit: cover;

      @media only screen and (max-width: 768px) {
        height: 75vh;
        width: auto;
      }
    }
  }

  .content__wraper {
    position: relative;
    max-width: 950px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    @media only screen and (max-width: 768px) {
      padding: 0 6%;
    }
    .content {
      color: #12171d !important;

      * {
        color: #12171d !important;
      }
    }
  }

  .share__wrap {
    @media only screen and (min-width: 769px) {
      position: absolute;
      top: 5rem;
      left: -30%;
    }
    h4 {
      margin-top: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #394149;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 45px;
      @media only screen and (max-width: 768px) {
        flex-direction: row;
        font-size: 0;
        justify-content: space-between;
      }

      button > svg {
        padding-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }

  .tags_wrap {
    max-width: 950px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      padding: 0 6%;
    }

    @media only screen and (min-width: 769px) {
      padding-top: 3rem;
      padding-bottom: 2.5rem;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 3rem;
    }

    > h4 {
      font-size: 14px;
      line-height: 19px;
      color: #12171d;
      margin-bottom: 1rem;
    }

    > div {
      display: flex;
      gap: 10px;

      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .tag {
      display: inline-block;
      border: 1px solid #394149;
      border-radius: 999px;
      padding: 10px 24px;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .youMightAlsoLike__wrap {
    padding: 5rem 9.25%;

    @media only screen and (max-width: 768px) {
      padding: 0 6%;
    }

    > h4 {
      font-family: "Krona One";
      font-style: normal;
      font-weight: 400;
      font-size: 74px;
      line-height: 92px;
      color: #12171d;
      margin-bottom: 2.5rem;
      @media only screen and (max-width: 768px) {
        font-size: 34px;
        line-height: 42px;
      }
    }
    .articles__wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 10rem;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 7.5rem;
        padding-bottom: 5rem;

        > article {
          width: 100%;
        }
      }
    }
  }
}
