.ourTechnology {
  position: relative;
  min-height: 1200px;
  height: max-content;
  color: var(--dark);
  padding: 80px 8.54% 0 13.07%;
  font-family: "Manrope", sans-serif;
  font-size: 4.2rem;

  @media only screen and (max-height: 768px) {
    min-height: unset;
  }

  .ourTechnology__mobile_list {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .item_wraper {
      border-bottom: 1px solid #eff1f3;

      > h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
      }

      &.active > h3 {
        color: #0e65d9;
      }
    }

    .item_para {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      max-height: 0;
      overflow: hidden;
      transition: max-height ease-in-out 0.5s;
      margin: 4rem 0;

      &.active {
        max-height: 500px;
      }
    }
  }

  .ourTechnology__h1 {
    width: min-content;
    margin: 0;
    padding: 0 0 80px;
    font-family: "Krona One", sans-serif;
    font-weight: 400;
    font-size: 7.4rem;
    line-height: 9.2rem;
  }

  .ourTechnology__wrap {
    display: flex;
    justify-content: space-between;

    .ourTechnology__wrap-tech {
      width: 40%;

      .ourTechnology__technology {
        margin: 0;
        padding: 0 0 45px;
        font-weight: 500;
        line-height: 5.7rem;
        padding-bottom: 45px;
        transition: 0.4s;

        &.active {
          color: var(--blue);
        }
      }
    }

    .ourTechnology__wrap-tools {
      width: 50%;
      margin-top: 0px;
      transition: 1s;

      .ourTechnology__tools {
        margin: 0;
        padding: 0 0 50px;
        font-weight: 400;
        line-height: 6.4rem;
        color: var(--gray);
        transition: 0.4s;

        &.active {
          color: var(--dark);
        }
      }
    }
  }

  .ourTechnology__line {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 9.27%;
    background-color: var(--gray);
    z-index: -1;

    .ourTechnology__active-line {
      position: absolute;
      top: 34%;
      left: 0;
      height: 295px;
      width: 1px;
      background-color: var(--blue);
    }

    &:nth-last-child(1) {
      left: 44.47%;

      .ourTechnology__active-line {
        top: 64%;
      }
    }
  }
}
