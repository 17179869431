.accordion {
  flex-grow: 1;

  .accordion-item {
    border: none;
    display: flex;
    flex-direction: column;

    .accordion-header {
      background: #fff;

      .accordion-button {
        display: flex;
        align-items: center;
        color: var(--dark);
        background-color: transparent;
        border: none;
        border-bottom: 1px solid var(--gray);
        box-shadow: none;

        .contactAndEstimate__number {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 65px;
          // height: 65px;
          width: 6.5rem;
          height: 6.5rem;
          margin: 0;
          font-weight: 600;
          // font-size: 18px;
          // line-height: 45px;
          font-size: 1.8rem;
          line-height: 4.5rem;
          color: var(--dark-gray);
          border: 1px solid var(--gray);
          border-radius: 50%;
          transition: 0.3;

          &.active {
            border: 1px solid var(--blue);

            .contactAndEstimate__checked-icon {
              filter: invert(32%) sepia(88%) saturate(1338%) hue-rotate(194deg) brightness(88%) contrast(103%);
            }
          }
        }

        .contactAndEstimate__question {
          padding-left: 15px;
          margin: 0;
          font-weight: 500;
          // font-size: 18px;
          // line-height: 45px;
          font-size: 1.8rem;
          line-height: 4.5rem;
        }

        &::after {
          content: none;
        }
      }

      &[data-disabled="true"] {
        .accordion-button {
          cursor: auto;

          &:not(.collapsed) {
            cursor: auto;
          }
        }
      }

      &[data-disabled="false"] {
        .accordion-button {
          cursor: pointer;

          &:not(.collapsed) {
            cursor: auto;
          }
        }
      }
    }

    .accordion-collapse.collapse,
    .accordion-collapse.collapse.show,
    .accordion-collapse.collapsing {
      .accordion-body {
        display: flex;
        flex-direction: column;
        padding: 0 9.27% 0 35px;
        height: calc(100vh - 112px - 75px - (3 * 98px));

        .contactAndEstimate__wrap-rectangles {
          height: 70%;
          max-height: 370px;
          padding: 0 0 35px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .contactAndEstimate__rectangle {
            position: relative;
            display: flex;
            align-items: flex-end;
            width: 23%;
            padding: 0 25px 32px;
            margin-top: 35px;
            font-family: "Krona One", sans-serif;
            font-weight: 400;
            // font-size: 18px;
            // line-height: 22px;
            font-size: 1.8rem;
            line-height: 2.2rem;
            border: 1px solid var(--gray);
            cursor: pointer;
            @media only screen and (max-width: 768px) {
              padding: 5px;
              width: 49%;
              margin-top: 5px;
            }

            &.active {
              border: 1px solid var(--blue);
              color: var(--blue);
            }

            @media only screen and (min-width: 768px) {
              &.active::before {
                position: absolute;
                top: 16px;
                right: 16px;
                line-height: 16px;
                text-align: center;
                content: "";
                color: #fff;
                // width: 34px;
                // height: 34px;
                width: 3.4rem;
                height: 3.4rem;
                border-radius: 50%;
                background-color: var(--blue);
              }

              &.active::after {
                position: absolute;
                top: 16px;
                right: 16px;
                content: "";
                // width: 34px;
                // height: 34px;
                width: 3.4rem;
                height: 3.4rem;
                background-image: url("../../../svg/contact_estimate_checked.svg");
                background-repeat: no-repeat;
                background-position: center;
                filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(64deg) brightness(104%) contrast(104%);
              }
            }
          }
        }

        .button {
          align-self: flex-end;
          display: flex;
          width: 40%;
          align-items: center;
          justify-content: space-between;
          // padding: 21px 42px 21px 53px;
          padding: 2.1rem 4.2rem 2.1rem 5.3rem;
          text-transform: uppercase;
          font-weight: 700;
          // font-size: 18px;
          // line-height: 45px;
          font-size: 1.8rem;
          line-height: 4.5rem;
          transition: 0.3s;

          &:disabled {
            cursor: auto;
            background-color: var(--gray);
            // opacity: 30%;
          }

          &:not(:disabled) {
            &:hover {
              background-color: var(--blue);
            }
          }
        }
      }
    }

    &:nth-last-of-type(1) {
      .accordion-button {
        border: none;
      }
    }

    .accordion-button:not(.collapsed) {
      .contactAndEstimate__number {
        color: var(--blue);
      }
    }

    &:nth-last-child(1) {
      .accordion-collapse.collapse,
      .accordion-collapse.collapse.show,
      .accordion-collapse.collapsing {
        .accordion-body {
          .button {
            text-transform: none;
            // background-color: var(--blue);

            // &:not(:disabled) {
            //     &:hover {
            //         background-color: var(--blue);
            //         opacity: 80%;
            //     }
            // }
          }
        }
      }
    }
  }
}
