.projectCard {
    position: relative;
    max-width: 765px;
    margin: 32px 0;
    padding: 34px 0 30px;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope', sans-serif;
    color: var(--dark);
    z-index: 10;

    .projectCard__wrap-img {
        .projectCard__img {
            max-width: 100%;
            height: auto;
        }
    }

    .projectCard__service {
        padding: 24px 0 0;
        margin: 0;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: var(--blue);
    }

    .projectCard__title {
        padding: 0 0 16px;
        margin: 0;
        font-family: 'Krona One', sans-serif;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
    }

    .projectCard__description {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
    }

    &:nth-of-type(2n-1) {
        align-self: start;

        &::after {
            position: absolute;
            width: 30%;
            height: 100%;
            content: '';
            top: 0;
            left: -35px;
            background-color: var(--gray);
            z-index: -10;
        }
    }
}

// mobile devices:
@media only screen and (max-width: 768px) {
    .projectCard {

        .projectCard__wrap-img {
            height: 395px;
            // background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .projectCard__title {
            font-size: 4rem;
            font-size: max(4rem, 32px);
            line-height: 5rem;
            line-height: max(5rem, 40px);
        }

        &:nth-of-type(2n-1) {
            align-self: start;
            margin-left: 10px;

            &::after {
                position: absolute;
                width: 30%;
                height: 100%;
                content: '';
                top: 0;
                left: -35px;
                background-color: var(--gray);
                z-index: -10;
            }
        }
    }
}