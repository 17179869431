.header {
  position: relative;
  width: 100%;
  min-height: 885px;
  background: linear-gradient(0deg, rgba(238, 241, 245, 1) 100%, rgba(230, 233, 240, 1) 100%);
  // overflow-x: hidden;
  overflow: hidden;

  .header__aside-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 6.1%;
    left: 4.64%;
    height: 12rem;
    transform: translate(-50%, 0);
    z-index: 10;
    border: none;

    .header__scroll {
      width: fit-content;
      // margin-right: 14px;
      margin-right: 1.4rem;
      margin-bottom: 0;
      transform: rotate(90deg) translate(50%, -50%);
      font-family: 'Manrope', sans-serif;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: 0.04em;
      text-align: center;
      color: var(--dark-btn);
    }

    .header__wrap-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      .header__arrow {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .header__wrap-circles {
    position: absolute;
    bottom: 7.46%;
    left: 9.27%;
    width: 172px;
    height: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .carouselItem__wrap-human.animation {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 11.72%;
    width: 42.19%;

    .carouselItem__human {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      height: auto;
    }

    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 50%;
      right: 0;
      height: 50%;
      width: 100%;

      .carouselItem__human {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        max-height: 100%;
        width: auto;
      }
    }
  }
}

// media query:

@media only screen and (max-width: 1850px) {
  .header {
    min-height: 800px;
  }
}

@media only screen and (max-width: 1600px) {
  .header {
    min-height: 720px;
  }
}

@media only screen and (max-width: 1500px) {
  .header {
    min-height: 680px;
  }
}

@media only screen and (max-width: 1400px) {
  .header {
    min-height: 635px;
  }
}

@media only screen and (max-width: 1350px) {
  .header {
    min-height: 590px;
  }
}

@media only screen and (max-width: 1199px) {
  .header {
    min-height: 530px;
  }
}
@media only screen and (max-width: 1100px) {
  .header {
    min-height: 490px;
  }
}
@media only screen and (max-width: 1023px) {
  .header {
    min-height: 450px;
  }
}

@media only screen and (max-width: 929px) {
  .header {
    min-height: 410px;
  }
}

@media only screen and (max-width: 879px) {
  .header {
    // min-height: 410px;
    .header__wrap-circles {
      bottom: 4.46%;
    }
  }
}

// mobile devices:

@media only screen and (max-width: 768px) {
  .header {
    min-height: 885px;
    .header__aside-btn {
      display: none;
    }
    .header__wrap-circles {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
