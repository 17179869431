.arrowButtonSlider__play-icon {
    width: 64px;
    margin-bottom: 30px;
    background-color: transparent;
    outline: none;
    border: none;
    filter: invert(32%) sepia(63%) saturate(7019%) hue-rotate(207deg) brightness(94%) contrast(89%);

    &:disabled {
        filter: invert(23%) sepia(28%) saturate(286%) hue-rotate(169deg) brightness(91%) contrast(88%);
    }

    &:nth-of-type(1) {
        transform: translate(0, -50%) rotate(180deg);
        margin-bottom: 0;
    }
}