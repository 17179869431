.mailAndPhone {
  width: 50%;

  .mailAndPhone__p-sm {
    margin: 0 0 11px;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 3.2rem;
  }

  .mailAndPhone__p-lg {
    margin: 0 0 40px;
    font-family: "Krona One", sans-serif;
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 4rem;

    &.mail {
      text-decoration: underline 1px;
    }
  }
}

// mobile devices:

@media only screen and (max-width: 768px) {
  .mailAndPhone {
    width: 100%;

    .mailAndPhone__p-sm {
      font-size: 3.67rem;
      line-height: 6.53rem;
    }

    .mailAndPhone__p-lg {
      margin: 0 0 20px;
      font-size: 4.08rem;
      @media only screen and (max-width: 416px) {
        font-size: 3.6rem;
      }
      line-height: 5.1rem;
      word-break: break-all;
    }
  }
}
