.articleCard__wrap-article {
  position: relative;
  width: 46%;
  font-size: 18px;

  .articleCard__article-img {
    max-width: 100%;
    height: auto;
  }
}

.articleCard__title {
  margin: 24px 0 24px;
  font-weight: 500;
  font-size: 34px;
  line-height: 48px;
}

.articleCard__description {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.articleCard__wrap-footer {
  margin: 37px 0 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  .articleCard__wrap-author {
    display: flex;
    align-items: center;

    .articleCard__wrap-avatar {
      margin: 0 24px 0 0;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
        content: "";
        width: 75%;
        height: 63px;
        background-color: var(--blue);
        z-index: -1;
      }
    }

    .articleCard__wrap-txt {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .articleCard__name {
        margin: 0 0 3px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      .articleCard__profession {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #b0b0b0;
      }
    }
  }
}
