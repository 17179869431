/* animation for Header: */

@keyframes bgc-right {
    /* 100% { */
        /* height: 100%; */
    /* } */
}

@keyframes bgc-right-back {
    0% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

@keyframes ellipse {
    100% {
        transform: translate(50%, -50%) scale(1);
    }
}

@keyframes ellipse-back {
    0% {
        transform: translate(50%, -50%) scale(1);
    }

    100% {
        transform: translate(50%, -50%) scale(0);
    }
}

@keyframes next-txt {
    100% {
        transform: translate(0, 0);
    }
}

@keyframes next-txt-back {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 80%);
    }
}

@keyframes human {
    /* 100% {
        transform: scale(1);
    } */
}

@keyframes human-back {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.3);
    }
}

@keyframes rock {
    100% {
        transform: scale(1) rotate(0) translateY(0);
    }
}

@keyframes rock-back {
    0% {
        transform: scale(1) rotate(0) translateY(0);
    }

    100% {
        transform: scale(0) rotate(-90deg) translateY(0);
    }
}

@keyframes opacity {
    100% {
        opacity: 1;
    }
}

@keyframes opacity-back {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes move-to-top {
    100% {
        top: 0;
    }
}

@keyframes move-to-top-back {
    0% {
        top: 0;
    }

    100% {
        top: 90px;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

/* animation for menu */
@keyframes float-menu {
    0% {
        transform: translatey(20px);
    }

    100% {
        transform: translatey(0);
    }
}
@keyframes float-menu-back {
    0% {
        transform: translatey(0);
    }

    100% {
        transform: translatey(-20px);
    }
}

/* animation for indicators */
@keyframes spin {
    from {
        stroke-dasharray: 0 157;
    }

    to {
        stroke-dasharray: 157 157;
    }
}