@import "~bootstrap/scss/bootstrap";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  overflow-x: hidden;
  // height: 500vh;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  .App__bgc_gray {
    // background-color: #EFF1F3;
    background-color: var(--gray);
  }
}


// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }


// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }