.meetOurTeam {
  display: flex;
  width: 100%;
  color: #FFF;
  font-family: 'Manrope', sans-serif;

  .meetOurTeam__title {
    width: 37.08%;
    width: 712px;
    padding: 11.5rem 8.1rem 0 17.5rem;

    .meetOurTeam__h1 {
      margin: 0;
      font-weight: 400;
      font-size: 7.4rem;
      line-height: 9.2rem;
      font-family: 'Krona One', sans-serif;
    }

    .meetOurTeam__p {
      margin: 0;
      padding: 36px 90px 0 0;
      font-weight: 600;
      font-size: 2rem;
      line-height: 3.8rem;
    }
  }

  .meetOurTeam__wrap-team {
    position: relative;
    height: 100%;
    padding: 0 10px 0 0;

    .meetOurTeam__team {
      height: 83%;
      max-width: 885px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 11.4rem 0 0;
    }

    .meetOurTeam__wrap-line {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 885px;
      z-index: 1;

      .meetOurTeam__line {
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        left: 50%;
        background-color: #394149;
        z-index: -1;

        .meetOurTeam__active-line {
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 1px;
          background-color: var(--blue);
          transition: var(--transition-for-line);
        }

        .meetOurTeam__active-line.active {
          height: 500px;
        }

        &:nth-child(2) {
          left: 204px;

          .meetOurTeam__active-line {
            // top: 89%;
            bottom: 0;
            top: unset;
          }

          .meetOurTeam__active-line.active {
            height: 384px;
          }
        }

        &:nth-child(3) {
          left: auto;
          right: 204px;

          .meetOurTeam__active-line {
            top: 40%;
          }

          .meetOurTeam__active-line.active {
            height: 465px;
          }
        }
      }
    }
  }
}

// media query:

@media only screen and (max-width: 1199px) {
  .meetOurTeam {

    .meetOurTeam__wrap-team {

      .meetOurTeam__wrap-line {

        .meetOurTeam__line {

          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 999px) {
  .meetOurTeam {

    .meetOurTeam__wrap-team {

      .meetOurTeam__wrap-line {

        .meetOurTeam__line {

          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .meetOurTeam {
    flex-direction: column;
    padding: 57px 0;

    .meetOurTeam__title {
      width: 100%;
      padding: 0 6.04%;

      .meetOurTeam__h1 {
        width: 60%;
        font-size: 34px;
        line-height: 42px;
      }

      .meetOurTeam__p {
        width: 100%;
        padding: 20px 0 0;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .meetOurTeam__wrap-team {
      padding: 0;

      .meetOurTeam__wrap-line {
        display: none;
      }
    }
  }
}