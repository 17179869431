.line {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: var(--gray);
    z-index: -1;

    .active-line {
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 1px;
        background-color: var(--blue);
        transition: 0.5s;
    }

    .active-line.active {
        height: 295px;
    }
}