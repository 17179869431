.person__person {
  position: relative;
  padding: 30px 20px 20px 20px;
  max-height: 586px;
  // height: 40%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: hidden;

  &:nth-child(1),
  &:nth-last-child(1) {
    &::after {
      content: "";
      position: absolute;
      top: -20px;
      left: -30px;
      width: calc(50% + 29px);
      height: calc(100% + 40px);
      background-color: #394149;
      z-index: -99;
      transform: translateY(-100%);
      transition: 0.8s ease-in-out;
    }

    .person__wrap-img {
      overflow: hidden;
    }
  }

  &:nth-last-child(1) {
    &::after {
      top: -7%;
      left: 50%;
    }
  }

  &:nth-child(2n) {
    margin: 230px 0 0;
  }

  &:nth-child(4n) {
    margin: 280px 0 150px;
  }

  .person__wrap-img {
    height: auto;
    position: relative;
    width: 369px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: calc(-100% - 30px);
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: var(--blue);
      z-index: -99;
      // transition: 1s linear;
      transition: 0.8s ease-in-out;
    }

    .person__img {
      position: relative;
      max-width: 100%;
      height: auto;
      top: calc(-100% - 30px);
      left: 0;
      // transition: 0.5s linear;
      // transition-delay: 0.5s;
      transition: 0.8s ease-in-out 0.4s;
    }
  }

  .person__name {
    margin: 19px 0 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    letter-spacing: 0.05em;
    opacity: 0;
    transition: 1s 0.4s;
  }

  .person__profession {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    opacity: 0;
    transition: 1s 0.4s;
  }

  .person__links {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    opacity: 0;
    transition: 1s 0.4s;

    >a {
      color: inherit;
      text-decoration: none;
      margin-right: 8px;
    }
  }

  &.active {
    &::after {
      transform: translateY(0);
    }

    .person__wrap-img {
      &::before {
        top: 0;
      }

      .person__img {
        top: 0;
      }
    }

    .person__name, .person__profession, .person__links {
      opacity: 1;
    }
  }
}

// media query:

@media only screen and (max-width: 1503px) {
  .person__person {
    &:nth-child(2n),
    &:nth-child(4n) {
      margin: 150px 0;
    }
  }
}

// mobile devices:
@media only screen and (max-width: 768px) {
  .person__person {
    &:nth-child(2n),
    &:nth-child(4n) {
      margin: 120px 0;
    }

    &.active {
      margin: 0 auto;

      .person__wrap-img {
        max-width: 100%;
      }
    }
  }
}
