.ourCasestudy {
  position: relative;
  width: 100%;
  min-height: 1790px;
  color: #FFF;
  font-family: 'Manrope', sans-serif;
  z-index: 1;

  .ourCasestudy__h1 {
    position: absolute;
    top: -130px;
    left: 175px;
    width: min-content;
    font-family: 'Krona One', sans-serif;
    font-weight: 400;
    font-size: 74px;
    line-height: 92px;
  }

  .ourCasestudy__main-project {
    display: flex;
    flex-direction: column;
    margin: 0 9.22% 64px 8.76%;
    width: 80%;

    .ourCasestudy__wrap-project {
      position: relative;
      padding-top: 144px;
      display: flex;
      justify-content: flex-end;

      .ourCasestudy__wrap-txt {
        position: absolute;
        bottom: 40px;
        left: 0;
        z-index: 10;

        .ourCasestudy__service {
          margin: 0 10px;
          font-weight: 800;
          font-size: 18px;
          line-height: 25px;
          color: var(--blue);
          text-transform: uppercase;
        }

        .ourCasestudy__title {
          margin: 0 0 15px;
          font-family: 'Krona One', sans-serif;
          font-weight: 400;
          font-size: 140px;
          line-height: 175px;
        }

        .ourCasestudy__description {
          margin: 0 10px;
          width: 60%;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
        }
      }

      .ourCasestudy__rectangle {
        position: relative;
        width: 68%;
        max-width: 1086px;
        height: 712px;
        background-color: #1DB8BA;
        cursor: pointer;

        .ourCasestudy__wrap-project-img {
          position: absolute;
          bottom: 0;
          left: -36%;

          .ourCasestudy__project-img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .ourCasestudy__wrap-h4 {
      display: flex;
      margin: 139px 0 0 0;

      .ourCasestudy__h4 {
        margin: 0 37px 0 0;
        font-family: 'Krona One', sans-serif;
        font-weight: 400;
        font-size: 34px;
        line-height: 42px;
      }
    }
  }

  .ourCasestudy__other-projects {
    display: flex;
    justify-content: space-around;
    margin: 0 9.22% 0 8.76%;

    .ourCasestudy__wrap-project {
      display: flex;
      flex-direction: column;
      margin: 0 35px 0 0;

      .ourCasestudy__project-img {
        max-width: 100%;
        height: auto;
      }

      .ourCasestudy__service {
        margin: 24px 0 4px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: var(--blue);
      }

      .ourCasestudy__name {
        font-family: 'Krona One', sans-serif;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
      }

      .ourCasestudy__description {
        margin: 16px 0 0;
        padding: 0 19% 0 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}