.othersSay {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 1187px;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Manrope', sans-serif;
  color: var(--dark);

  .othersSay__h1 {
    margin: 0;
    padding: 82px 0 0 178px;
    width: min-content;
    font-family: 'Krona One', sans-serif;
    font-weight: 400;
    font-size: 74px;
    line-height: 92px;
  }

  .othersSay__wrap-slider {
    flex-grow: 1;
    position: relative;
    padding: 37px 0 0;

    // .othersSay__slider {}

    .othersSay__wrap-circles {
      position: absolute;
      bottom: 365px;
      left: 50%;
      width: 172px;
      height: 39px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translate(-58%, 30px);

      img {
        cursor: pointer;
      }

      .othersSay__circle {
        outline: none;
        background-color: transparent;
        border: none;

        &.active {
          width: 35px;
          height: 35px;
        }
      }
    }

    .othersSay__indicator {
      position: absolute;
      top: 12%;
      cursor: pointer;
      height: 65px;
      width: 65px;
      border: 1px solid var(--gray);
      border-radius: 50px;
      background-color: transparent;

      .othersSay__play-icon {
        max-width: 100%;
        height: auto;
      }

      &.left {
        left: 20%;
        transform: rotate(180deg);
      }

      &.right {
        right: 20%;
      }
    }
  }

  .othersSay__line {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 50%;
    background-color: var(--gray);
    z-index: -1;

    .othersSay__active-line {
      position: absolute;
      top: 9%;
      left: 0;
      height: 0;
      height: 0;
      width: 1px;
      background-color: var(--blue);
      z-index: -1;
      transition: var(--transition-for-line);
    }

    .othersSay__active-line.active {
      height: 175px;
    }

    &:nth-of-type(2) {
      left: 36.5%;

      .othersSay__active-line {
        position: absolute;
        top: 89%;
      }

      .othersSay__active-line.active {
        height: 129px;
      }
    }

    &:nth-of-type(3) {
      left: 63.49%;

      .othersSay__active-line {
        display: none;
      }
    }
  }
}