.howWeWork {
  width: 100%;
  // min-height: 1385px;
  display: flex;
  font-family: "Manrope", sans-serif;

  .howWeWork__wrap {
    width: 42.81%;
    padding: 0 0 0 9.27%;

    .howWeWork__h1 {
      padding: 0 40% 4.1rem 0;
      padding: 0 40% max(4.1rem, 30px) 0;
      margin: 0;
      font-family: "Krona One", sans-serif;
      font-weight: 400;
      font-size: 7.4rem;
      font-size: max(7.4rem, 34px);
      line-height: 9.2rem;
      line-height: max(9.2rem, 42px);
      color: var(--dark);
    }

    .howWeWork__p {
      margin: 0;
      padding: 0 20% 30px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #202428;
    }

    &:nth-child(2) {
      padding: 0;
      width: 57.19%;
      position: relative;

      .howWeWork__rectangle {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        // height: 332px;
        padding: 3rem;
        border: 1px solid var(--gray);
        border-bottom: none;
        background-image: none;
        background-color: #fff;
        transition: all ease-in-out 0.3s;

        // &:last-child {
        //   position: absolute;
        //   bottom: 0;
        //   right: 84%;
        //   width: 74.85%;

        //   &::before {
        //     left: -125%;
        //   }

        //   &:hover {
        //     &::before {
        //       left: -20%;
        //     }
        //   }
        // }

        &:nth-last-child(2) {
          z-index: 2;
        }

        .howWeWork__number {
          width: 17%;
          font-family: "Krona One", sans-serif;
          font-weight: 400;
          font-size: 40px;
          line-height: 50px;
          text-align: center;
          color: #c4c4c4;
          z-index: 20;
        }

        &:hover {
          .howWeWork__p {
            max-height: 1000px !important;
          }
        }

        .howWeWork__txt {
          width: 40%;
          z-index: 20;

          .howWeWork__h2 {
            margin: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: var(--black);
          }

          .howWeWork__p {
            padding: 0 19% 0 0;
            line-height: 28px;
            color: var(--dark-gray);
            max-height: 0;
            overflow: hidden;
            transition: max-height ease-in-out 0.5s;

            max-width: 85%;

            span {
              font-weight: 700;
              color: var(--blue);
            }
          }
        }

        &:nth-of-type(2n) {
          .howWeWork__txt {
            width: 50%;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
            url(../../../img/AU_how_we_work_hover.png);
          transition: 1s;
          background-repeat: no-repeat;
          background-size: cover;
        }

        &:nth-child(1)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
            url(../../../img/how_we_work/ConceptualPhase_WWW.jpg);
        }
        &:nth-child(2)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 15%, rgba(255,255,255,0.7) 50%, #ffffff 79%),
            url(../../../img/how_we_work/DesignPhase_www.png);
        }
        &:nth-child(3)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
            url(../../../img/how_we_work/ProductionPhase_www.jpg);
        }
        &:nth-child(4)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 15%, rgba(255,255,255,0.9) 50%, #ffffff 100%),
            url(../../../img/how_we_work/Implementation_Phase.png);
        }
        &:nth-child(5)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
            url(../../../img/how_we_work/EvaluationPhase_www.jpg);
        }
        &:nth-child(6)::before {
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
            url(../../../img/how_we_work/improvement_opacity.png);
        }

        &:hover {
          &::before {
            left: 0;
          }
        }

        &:last-child {
          // position: relative;

          &::after {
            content: "";
            width: 110%;
            height: 100%;
            position: absolute;
            top: 17%;
            left: -5%;
            z-index: -5;
            background-color: var(--gray);
          }
        }
      }
    }
  }
}

// media query:
@media only screen and (max-width: 1000px) {
  .howWeWork {
    .howWeWork__wrap {
      padding: 0 4%;
      padding: 0 max(4%, 25px);

      .howWeWork__p {
        padding: 0 0 30px;
        font-size: 16px;
      }

      &:nth-child(2) {
        .howWeWork__rectangle {
          .howWeWork__number {
            width: 20%;
            font-size: 36px;
          }

          .howWeWork__txt {
            width: 50%;
          }

          &::before {
            background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 68%),
              url(../../../img/AU_how_we_work_hover.png);
          }
        }
      }
    }
  }
}

// mobile devices:
@media only screen and (max-width: 768px) {
  .howWeWork {
    flex-direction: column;

    .howWeWork__wrap {
      width: 100%;

      .howWeWork__p {
        font-size: 18px;
      }

      &:nth-child(2) {
        width: 100%;

        .howWeWork__rectangle {
          padding: 0 25px 40px 25px;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          &:last-child {
            position: inherit;
            bottom: unset;
            right: unset;
            width: inherit;

            &::before {
              left: 100%;
            }

            &:hover {
              &::before {
                left: inherit;
              }
            }
          }

          .howWeWork__number {
            font-size: 40px;
          }

          .howWeWork__txt {
            width: 90%;

            .howWeWork__h2 {
              padding: 14px 0 8px;
            }
          }

          &:nth-of-type(2n) {
            .howWeWork__txt {
              width: 80%;
            }
          }

          &::before {
            background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 35%, #ffffff 79%),
              url(../../../img/AU_how_we_work_hover.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
