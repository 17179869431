@import './constants.css';
@import './keyFrames.css';

:root {
  overflow-x: hidden;
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* media query: */
@media only screen and (max-width: 1650px) {
  :root {
    font-size: 9.5px;
  }
}

@media only screen and (max-width: 1600px) {
  :root {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1450px) {
  :root {
    font-size: 8.5px;
  }
}

@media only screen and (max-width: 1350px) {
  :root {
    font-size: 8px;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
    font-size: 7.5px;
  }
}

@media only screen and (max-width: 1199px) {
  :root {
    font-size: 7px;
  }
}

@media only screen and (max-width: 1100px) {
  :root {
    font-size: 6.5px;
  }
}

@media only screen and (max-width: 1023px) {
  :root {
    font-size: 6px;
  }
}

@media only screen and (max-width: 929px) {
  :root {
    font-size: 5.5px;
  }
}

@media only screen and (max-width: 879px) {
  :root {
    font-size: 4.9px;
  }
}

/* mobile:

@media only screen and (max-width: 768px) {
   .header {
    min-height: 410px;
  }
 } */