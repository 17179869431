.card-opinion {
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 60px 6.5% 0 6.5%;
  margin: 0;
  width: 48.3%;
  height: 452px;
  border: 1px solid #D9DADE;
  background-color: #fff;

  .card__wrap-casestudy {
    display: flex;
    align-items: center;

    .card__txt {
      margin: 0 8px 0 0;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .card__description {
    margin: 15px 0 37px;
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
  }

  .card__wrap-logo {
    margin: 35.5px 0;

    .card__logo {
      max-width: 100%;
      height: auto;
    }
  }

  .card__wrap-author {
    display: flex;
    align-items: center;

    .card__wrap-avatar {
      margin: 0 24px 0 0;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-25%, -50%);
        content: '';
        width: 75%;
        height: 63px;
        background-color: #D9DADE;
        z-index: -1;
      }
    }

    .card__wrap-txt {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .card__name {
        margin: 0 0 3px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      .card__profession {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #B0B0B0;
      }
    }
  }

  &.active {
    display: block;
  }

  &.left,
  &.right {
    display: block;
    transform: translate(-50%, 40%);
    left: 0;
    opacity: 0.4;
  }

  &.right {
    left: 100%;
  }
}