.menu {
  overflow: hidden;

  .navbar {
    position: absolute;
    // position: fixed;
    top: 0;
    left: 0;
    padding: 50px 9.22% 0 9.27%;
    width: 100%;
    z-index: 20;

    .container-fluid {
      padding: 0;

      .navbar-toggler {
        margin: 0 !important;
        border: none;
        color: #fff;

        &:focus {
          box-shadow: none;
        }

        .menu__wrap-menu {
          display: flex;
          justify-content: center;

          .menu__menu {
            padding: 0;
            margin: 0 12px 0 0;
            font-family: "Manrope", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            &.dark {
              color: var(--dark);
            }
          }
        }
      }
    }
  }
}

.offcanvas.offcanvas-top {
  padding: 50px 9.22% 0 9.27%;
  height: 100vh;
  border-bottom: none;
  background-color: var(--dark);
  transition: 0.8s ease-in-out;
  transition-delay: 0s;

  .offcanvas-header {
    .btn-close {
      padding: 0;
      margin: 0;
      width: 28px;
      height: 28px;
      background: transparent url(../../svg/header_x.svg) center no-repeat;
      opacity: 1;
      border: none;
      outline: none;

      &:focus {
        box-shadow: none;
      }

      &::before {
        content: "Close";
        display: block;
        transform: translateX(-200%);
        color: #fff;
        font-family: "Manrope", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .offcanvas-body {
    display: flex;
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: visible;

    .navbar-nav {
      ul {
        margin: 0;
      }

      .nav-link {
        width: fit-content;
        padding-bottom: 0;
        font-family: "Manrope", sans-serif;
        font-weight: 700;
        // font-size: 5.4px;
        margin: 4vmin 0 0;
        font-size: 6vmin;
        line-height: 6vmin;
        letter-spacing: 0.01em;
        color: #fff;
        transform: translateY(0);
        opacity: 0;
        animation: float-menu 0.6s linear forwards 0.3s, opacity 1s linear forwards 0.6s;

        &.active {
          animation: float-menu-back 0.6s linear forwards 0.3s, opacity-back 0.5s linear both 0.6s;
        }
      }
    }

    .menu__social-media-wrap {
      margin-bottom: 19vmin;
      // margin-bottom: 120px;
      align-self: flex-end;
      // width: 30%;
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: 700;
      padding-right: 40px;
      font-family: "Manrope", sans-serif;
      color: #fff;

      .menu__social-media-txt {
        margin-bottom: 10px;
        transform: translateY(0);
        opacity: 0;
        animation: float-menu 0.6s linear forwards 0.5s, opacity 1s linear forwards 0.6s;

        &.active {
          animation: float-menu-back 0.6s linear forwards 0.5s, opacity-back 0.5s linear both 0.6s;
        }
      }

      .menu__social-media-wrap-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .nav-link {
          padding: 0 20px 0 0;
          margin: 0;
          font-weight: 500;
          font-size: 2rem;
          line-height: 5rem;
          animation: float-menu 0.6s linear forwards 0.55s, opacity 1s linear forwards 0.65s;

          .menu__icon {
            display: none;
            filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(8deg) brightness(106%) contrast(108%);
            // padding-bottom: 50px;
          }

          .menu__p {
            margin: 0;
          }

          &.active {
            animation: float-menu-back 0.6s linear forwards 0.55s, opacity-back 0.5s linear both 0.65s;
          }
        }
      }

      .button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        padding: 2.1rem 4.2rem;
        margin-top: 3.7rem;
        // line-height: 87px;
        line-height: 4.5rem;
        font-size: 1.8rem;
        font-weight: 700;
        opacity: 0;
        animation: float-menu 0.6s linear forwards 0.65s, opacity 1s linear forwards 0.75s;

        &.active {
          animation: float-menu-back 0.6s linear forwards 0.65s, opacity-back 0.5s linear both 0.75s;
        }
      }
    }
  }
}

// mobile devices:
@media only screen and (max-width: 768px) {
  .offcanvas.offcanvas-top {
    padding: 50px 25px 0px;

    .offcanvas-header {
      .btn-close {
        width: 20px;
        height: 20px;

        &::before {
          display: none;
        }
      }
    }

    .offcanvas-body {
      flex-direction: column;
      padding-bottom: 0;

      .flex-grow-1 {
        flex-grow: 2 !important;
        // flex-grow: 0 !important;
      }

      .navbar-nav {
        // height: 60%;
        ul {
          padding: 0;
        }
      }

      .menu__social-media-wrap {
        min-height: 180px;
        flex-grow: 1;
        // min-height: 180px;
        // flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        align-self: auto;
        padding: 0;
        font-size: 18px;
        line-height: 32px;

        .menu__social-media-txt {
          animation: float-menu 0.6s linear forwards 1s, opacity 1s linear forwards 1.15s;

          &.active {
            animation: float-menu-back 0.6s linear forwards 1s, opacity-back 0.5s linear both 1.15s;
          }
        }

        .menu__social-media-wrap-links {
          // max-width: 400px;

          .nav-link {
            width: 24px;
            height: auto;

            .menu__icon {
              display: block;
              width: 24px;
              height: auto;
            }

            .menu__p {
              display: none;
            }

            animation: float-menu 0.6s linear forwards 1s, opacity 1s linear forwards 1.15s;

            &.active {
              animation: float-menu-back 0.6s linear forwards 1s, opacity-back 0.5s linear both 1.15s;
            }
          }
        }

        .button {
          width: calc(100% + 50px + 2rem);
          margin-left: calc(-25px - 1rem);
          padding: 0 25px;
          line-height: 87px;
          font-size: 18px;
          animation: float-menu 0.6s linear forwards 1.15s, opacity 1s linear forwards 1.25s;

          &.active {
            animation: float-menu-back 0.6s linear forwards 1.15s, opacity-back 0.5s linear both 1.25s;
          }
        }
      }
    }
  }
}

.menu__logo {
  max-width: 280px;

  @media only screen and (max-width: 768px) {
    max-width: 140px;
  }
}
