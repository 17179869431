.listArticles {
  width: 100%;
  position: relative;
  font-family: "Manrope", sans-serif;

  .listArticles__black_space {
    position: relative;
    margin: -46px 0 0;
    width: 100%;
    height: 154px;
    background-color: var(--dark);
    text-align: center;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    color: #fff;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .listArticles__wrap_magazine_card {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
