.contactForm {
  .contactForm__form {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
      .input__wrap,
      .select__wrap {
        width: 100%;
        margin-right: 0 !important;
      }

      .button {
        width: 100% !important;
      }

      .checkbox {
        .checkbox__input {
          flex-shrink: 0;
        }
        .checkbox__label {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .input__wrap:nth-child(1),
    .input__wrap:nth-child(3) {
      margin-right: 3%;
    }

    #budget::before {
      content: "$";
      text-align: center;
      position: relative;
      left: 35px;
    }

    .button {
      justify-self: flex-end;
      align-self: flex-end;
      padding: 21px 42px 21px 53px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 41%;
      height: 87px;
      font-weight: 700;
      font-size: 18px;
      line-height: 45px;
    }
  }
}
