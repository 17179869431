.radio__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    .radio__p {
        width: 100%;
        margin: 0;
        padding: 0 0 14px;
    }

    .radio__wrap-input {
        display: flex;
        .radio__input {
            padding: 0;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid var(--input-gray);
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;

            &:checked {
                position: relative;

                &::after,
                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                }

                &::after {
                    width: 12px;
                    height: 12px;
                    background-color: var(--blue);
                }

                &::before {
                    width: 24px;
                    height: 24px;
                    border: 2px solid var(--blue);
                }

            }
        }

        .radio__label {
            padding: 0 16px 0 10px;
            text-transform: capitalize;
        }
    }
}