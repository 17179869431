.button {
    padding: 1.6rem 3.4rem;
    background-color: var(--dark-btn);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #fff;
    outline: none;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s;

    &:hover {
        background-color: var(--blue);
    }

    &:disabled {
        background-color: var(--gray);
    }
}

// mobile devices:

@media only screen and (max-width: 768px) {
    .button {
        padding: 3.27rem 6.94rem;
        font-size: 3.27rem;
        line-height: 4.49rem;
    
    }
  }