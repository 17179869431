.footer {
    display: flex;
    flex-direction: column;

    .footer__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 81.51%;
        min-height: 15.8rem;
        margin: 0 auto;
        border-top: 1px solid var(--light-gray);
        font-family: "Manrope", sans-serif;

        .footer__menu {
            max-width: 790px;
            width: 70%;

            .footer__ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .footer__li {
                    .footer__a {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        text-decoration: none;
                        color: var(--dark);
                    }
                }
            }
        }
    }
}

// mobile devices:

@media only screen and (max-width: 768px) {
    .footer {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        padding: 43px 25px;

        .footer__wrap {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-top: 200px;
            border-top: none;

            .footer__menu {
                width: 100%;

                .footer__ul {
                    flex-direction: column;
                    align-items: flex-start;

                    .footer__li {
                        padding-top: 40px;
                        .footer__a {
                            font-size: 3.27rem;
                            line-height: 4.49rem;
                        }
                    }
                }
            }
        }
    }
}

.footer__logo {
    max-width: 140px;
}
