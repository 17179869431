.whoWeAre {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  // padding: 0 9.27%;
  min-height: 2096px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Manrope", sans-serif;
  color: var(--dark-btn);

  .whoWeAre__h2 {
    width: 100%;
    max-width: 1920px;
    // padding: 0 9.27%;
    margin: 0 auto;
    padding: 80px 9.27% 110px;
    font-weight: 600;
    font-size: 34px;
    line-height: 46px;
  }

  .whoWeAre__wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 61px;

    div:nth-child(1) {
      width: 46.25%;
    }

    div:nth-child(2) {
      width: 32.09%;
    }

    div:nth-of-type(2) {
      .whoWeAre__wrap-img {
        max-width: 496px;
      }
    }

    .whoWeAre__wrap-img:nth-child(1) {
      position: relative;
      margin-right: 3.89%;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-14%, -50%);
        content: "";
        width: 50%;
        max-width: 427px;
        height: 120%;
        background-color: var(--gray);
        z-index: -1;
      }
    }

    .whoWeAre__wrap-txt {
      width: 56.74%;
      max-width: 888px;
      padding-right: 6%;
      margin-right: 3.89%;

      .whoWeAre__h1 {
        margin: 0;
        padding: 89px 0 15px;
        font-family: "Krona One", sans-serif;
        font-weight: 400;
        font-size: 54px;
        line-height: 80px;
        @media only screen and (max-width: 768px) {
          font-size: 28px;
          line-height: 80px;
        }
      }

      .whoWeAre__description {
        text-align: justify;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.02em;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    &:nth-of-type(3) {
      align-items: center;

      .whoWeAre__wrap-img:nth-child(1) {
        text-align: right;

        &::before {
          left: auto;
          right: 0;
          transform: translate(-17%, -50%);
          width: 80%;
        }
      }

      .whoWeAre__p {
        margin: 0;
        max-width: 615px;
        padding: 0 12% 0 0;
        font-size: 24px;
        line-height: 42px;
        letter-spacing: 0.02em;

        .whoWeAre__bold {
          font-weight: 700;
        }

        .whoWeAre__blue {
          color: var(--blue);
          text-decoration: 1px solid underline;
        }
      }
    }
  }

  .whoWeAre__line {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 55.13%;
    background-color: var(--gray);
    z-index: -1;

    .whoWeAre__active-line {
      position: absolute;
      top: 28%;
      left: 0;
      height: 306px;
      width: 1px;
      background-color: var(--blue);
    }

    &:nth-last-child(1) {
      left: 91.1%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .whoWeAre {
    .whoWeAre__wrap {
      flex-direction: column;
      row-gap: 6rem;
      align-items: center;

      .whoWeAre__wrap-img {
        width: 90% !important;
      }

      div:nth-child(1),
      div:nth-child(2) {
        width: 90%;
      }
    }
  }
}
