.contactAndEstimate {
  min-height: 100vh;
  // height: 100vh;
  width: 100%;
  display: flex;
  color: var(--dark);
  font-family: "Manrope", sans-serif;

  .contactAndEstimate__left {
    display: flex;
    flex-direction: column;
    width: 32.44%;
    // padding: 186px 0 0 9.27%;
    padding: 18.6rem 0 0 9.27%;
    background-color: var(--gray);

    .contactAndEstimate__h1 {
      margin: 0;
      font-family: "Krona One", sans-serif;
      font-weight: 400;
      // font-size: 74px;
      // line-height: 92px;
      font-size: 7.4rem;
      line-height: 9.2rem;
    }

    .contactAndEstimate__p {
      margin: 0;
      // padding-top: 23px;
      padding-top: 2.3rem;
      width: 70%;
      font-weight: 600;
      // font-size: 18px;
      // line-height: 25px;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    .mailAndPhone {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .mailAndPhone__p-lg {
        // margin-bottom: 60px;
        margin-bottom: 6rem;
        // font-size: 20px;
        // line-height: 25px;
        font-size: 2rem;
        line-height: 2.5rem;

        &.mail {
          // margin-bottom: 15px;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .contactAndEstimate__right {
    width: 67.56%;
    // padding: 112px 9.27% 0 35px;
    padding: 11.2rem 9.27% 0 3.5rem;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: calc(132px - 11.2rem);
    }

    .contactAndEstimate__h3 {
      margin: 0;
      // padding: 0 0 30px;
      padding: 0 0 3rem;
      font-weight: 500;
      // font-size: 24px;
      // line-height: 45px;
      font-size: 2.4rem;
      line-height: 4.5rem;
    }
  }
}
