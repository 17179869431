.letsTalk {
  width: 81.51%;
  // width: 100%;
  min-height: 58rem;
  padding: 9.2rem 0 0;
  margin: 0 auto;
  // padding: 92px 178px 0 178px;
  font-family: "Manrope", sans-serif;
  color: var(--dark);

  .letsTalk__wrap-h1 {
    margin: 0 0 6.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .letsTalk__h1 {
      margin: 0;
      font-family: "Krona One", sans-serif;
      font-weight: 400;
      font-size: 7.4rem;
      line-height: 9.2rem;
    }

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 29%;
      min-width: fit-content;
      max-width: 445px;
      padding: 2.1rem 4.2rem;
      line-height: 4.5rem;
      font-weight: 700;
      font-size: 1.8rem;

      .button__svg {
        padding: 0 10px;
      }
    }
  }

  .letsTalk__wrap-contact {
    display: flex;
    justify-content: space-between;

    .letsTalk__contact-firstColumn {
      display: flex;
      flex-direction: column;
      width: 70%;
    }

    .letsTalk__wrap-office {
      width: 100%;

      .letsTalk__offices {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
          row-gap: 5rem;
        }
      }
    }

    .letsTalk__wrap-social-media,
    .letsTalk__wrap-office {
      .letsTalk__h3 {
        margin: 0 0 3rem;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 3.2rem;
      }

      .letsTalk__p {
        margin: 0;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 4.5rem;
      }
    }

    .letsTalk__wrap-social-media {
      margin-right: 10rem;

      .letsTalk__ul {
        margin: 0;
        padding: 0;
        list-style: none;

        .letsTalk__wrap {
          .letsTalk__a {
            display: flex;
            align-items: center;
            color: var(--dark);
            text-decoration: none;

            .letsTalk__icon {
              width: 2rem;
              max-width: 100%;
              height: auto;
              margin: 0 2rem 0 0;
            }
          }
        }
      }
    }
  }
}

// mobile devices:

@media only screen and (max-width: 768px) {
  .letsTalk {
    width: 100%;
    padding: 56px 0 0;

    .letsTalk__wrap-h1 {
      position: absolute;
      top: 43px;
      left: 25px;
      right: 25px;
      flex-direction: column;
      align-items: flex-start;

      .letsTalk__h1 {
        font-size: 8.16rem;
        line-height: 10.2rem;
        padding-bottom: 32px;
      }

      .button {
        width: 100%;
        max-width: 100%;
        padding: 21px 42px;
        line-height: 9.18rem;
        font-size: 3.67rem;

        .button__svg {
          padding: 0 10px;
        }
      }
    }

    .letsTalk__wrap-contact {
      flex-direction: column;

      .letsTalk__wrap-office {
        width: 100%;
      }

      .letsTalk__wrap-social-media,
      .letsTalk__wrap-office {
        .letsTalk__h3 {
          margin: 16px 0 10px;
          font-size: 3.67rem;
          line-height: 4.9rem;
        }

        .letsTalk__p {
          margin: 0;
          font-size: 3.67rem;
          line-height: 9.18rem;
        }
      }

      .letsTalk__wrap-social-media {
        .letsTalk__h3 {
          display: none;
        }

        .letsTalk__ul {
          margin: 52px 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .letsTalk__wrap {
            .letsTalk__a {
              .letsTalk__p {
                display: none;
              }

              .letsTalk__icon {
                width: 4.9rem;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
