.checkbox {
    display: flex;
    width: 100%;
    padding: 24px 0 60px;
    flex-direction: row;
    align-items: center;

    .checkbox__input {
        padding: 0 12px 0 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 2px solid var(--input-gray);
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;

        &:checked {
            position: relative;

            &::after,
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                width: 24px;
                height: 24px;
                transform: translate(-50%, -50%);
            }

            &::after {
                background-image: url('../../svg/contact_estimate_checked.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(250deg) brightness(107%) contrast(101%);
            }

            &::before {
                background-color: var(--blue);
                border: 2px solid var(--blue);
            }
        }
    }

    .checkbox__label {
        padding: 0 16px 0 10px;

        .checkbox__span {
            color: var(--red);
        }
    }
}