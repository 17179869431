.headerBlack {
    width: 100%;
    min-height: 377px;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Manrope', sans-serif;

    .headerBlack__wrap-title {
        padding: 40px 40px 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .headerBlack__h1 {
            margin: 0;
            font-family: 'Krona One', sans-serif;
            font-weight: 400;
            font-size: 6.4rem;
            font-size: max(6.4rem, 48px);
            line-height: 8rem;
            line-height: max(8rem, 60px);
            text-align: center;
        }

        .headerBlack__h2 {
            margin: 0;
            font-weight: 600;
            font-size: 2.4rem;
            font-size: max(2.4rem, 18px);
            line-height: 3.3rem;
            line-height: max(3.3rem, 25px);
            text-align: center;

            .headerBlack__blue {
                color: var(--blue);
            }
        }
    }

    .headerBlack__nav {
        width: 100%;
        border-top: solid 1px var(--dark-gray);

        .headerBlack__ul {
            height: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;

            .headerBlack__li {
                width: 17%;
                min-width: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;

                .headerBlack__btn {
                    width: 100%;
                    min-width: 200px;
                    padding: 0 10px;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 77px;
                    border: none;
                    border-right: solid 1px var(--dark-gray);
                    color: #fff;
                    background-color: transparent;
                    transition: 0.3s;

                    &:hover {
                        background-color: var(--blue);
                    }
                }

                &:nth-last-of-type(1) {
                    .headerBlack__btn {
                        border: none;
                    }
                }
            }
        }
    }
}

// mobile devices:
@media only screen and (max-width: 768px) {
    .headerBlack {

        .headerBlack__wrap-title {
            min-height: 375px;

            .headerBlack__h1 {
                padding-bottom: 20px;
            }

            .headerBlack__h2 {
                width: 80%;
            }
        }

        .headerBlack__nav {

            .headerBlack__ul {
                flex-direction: column;
                list-style: none;

                .headerBlack__li {
                    width: 100%;

                    .headerBlack__btn {
                        text-align: left;
                        width: 100%;
                        padding: 0 25px;
                        border: none;

                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            border-bottom: solid 1px var(--dark-gray);
                        }
                    }

                    &:nth-last-of-type(1) {
                        .headerBlack__btn {
                            &::after {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}