.lastIn {
  position: relative;
  font-family: "Manrope", sans-serif;

  .lastIn__header {
    padding: 80px 9.27% 50px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 7rem;
    }

    .lastIn__wrap-txt {
      .lastIn__p {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 48px;
        color: var(--blue);
        @media only screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      .lastIn__h1 {
        width: min-content;
        margin: 0;
        font-family: "Krona One", sans-serif;
        font-weight: 400;
        font-size: 74px;
        line-height: 92px;
        color: var(--dark);
        @media only screen and (max-width: 768px) {
          font-size: 34px;
          line-height: 42px;
        }
      }
    }

    .button {
      margin-bottom: 12px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .lastIn__wrap-slider {
    margin-left: 9.27%;
    overflow-x: hidden;
    // padding-left: 9.27%;
    margin-right: 9.37%;

    .lastIn__wrap-slider-card {
      position: relative;
      left: 0;
      // padding-left: 9.27%;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .alice-carousel__wrapper {
        width: calc(100% + 2rem);
      }

      // padding: 0 0 0 9.27%;
      .articleCard__wrap-article {
        // max-width: 445px;
        margin: 0 1rem;
        width: unset;
        flex-basis: 32%;

        .articleCard__title {
          margin: 18px 0 24px;
          font-size: 24px;
          line-height: 34px;
        }

        .articleCard__article-img {
          object-fit: cover;
          height: 300px;
          width: 100%;
          aspect-ratio: auto;
        }

        .articleCard__description {
          line-height: 28px;
        }

        .articleCard__wrap-footer {
          .readMore__wrap {
            justify-content: flex-end;
          }
        }
      }

      // &.left {
      //     // left: -15%;
      //     left: -200px;
      // }
    }

    .lastIn__wrap-play-icons {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 50%;
      left: 4.64%;
      transform: translate(-50%, 50%);

      @media only screen and (max-width: 768px) {
        flex-direction: row;
        left: 50%;
        top: unset;
        bottom: 0;
        column-gap: 5rem;

        > button:last-child {
          transform: translate(0, -50%);
        }
      }
    }
  }

  &::after {
    display: block;
    content: "";
    height: 1px;
    width: 81.51%;
    margin: 80px auto 0;
    background-color: var(--gray);
  }

  &:nth-last-of-type(1) {
    margin-bottom: 80px;

    @media only screen and (min-width: 769px) {
      .lastIn__wrap-slider .lastIn__wrap-play-icons {
        transform: translate(-50%, 75%);
      }
    }

    &::after {
      @media only screen and (min-width: 769px) {
        display: none;
      }
    }
  }
}
