.ourCaseStudyList {
    width: 100%;

    .ourCaseStudyList__main {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        margin: 0 auto;
        padding: 32px 0 120px;
        width: 65%;

        .ourCaseStudyList__line {

            .ourCaseStudyList__active-line {
                height: 317px;
                top: 85%;
            }

            &:nth-last-of-type(1) {
                left: 100%;
                transform: translateX(-100%);

                .ourCaseStudyList__active-line {
                    height: 147px;
                    top: 25%;
                }
            }
        }
    }
}

// mobile devices:
@media only screen and (max-width: 768px) {
    .ourCaseStudyList {

        .ourCaseStudyList__main {
            padding: 22px 25px 40px;
            width: 100%;

            .ourCaseStudyList__line {
                display: none;
            }
        }
    }
}