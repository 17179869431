.readMagazineCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 393px;
  padding: 57px 30px 68px;
  font-family: "Manrope", sans-serif;
  border: solid 1px var(--gray);
  transition: 0.3s;
  z-index: 4;
  flex-basis: 16%;

  @media only screen and (max-width: 768px) {
    height: unset;
  }

  .readMagazineCard__h1 {
    padding: 0 0 20px;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #0a1a19;
  }

  .readMagazineCard__p {
    margin: 0;
    line-height: 32px;
    color: var(--dark-gray);
    font-size: 18px;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .readMagazineCard__wrap-read-magazine {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    .readMagazineCard__txt {
      flex-grow: 1;
      margin: 0;
      font-weight: 800;
      font-size: 16px;
      color: var(--blue);
      cursor: pointer;
    }

    .readMagazineCard__arrow-icon {
      cursor: pointer;
      filter: invert(26%) sepia(50%) saturate(5748%) hue-rotate(207deg) brightness(93%) contrast(89%);
    }
  }

  &:hover {
    margin: -30px 0 0;
    background-color: var(--blue);
    border: solid 1px var(--blue);

    .readMagazineCard__h1,
    .readMagazineCard__p,
    .readMagazineCard__txt {
      color: #fff;
    }

    .readMagazineCard__arrow-icon {
      filter: invert(100%) sepia(2%) saturate(7417%) hue-rotate(111deg) brightness(109%) contrast(95%);
    }
  }
}
