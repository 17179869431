.textArea__wrap {
    width: 100%;
    padding: 24px 0 0;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    .textArea__label {
        color: var(--dark-btn);
        padding: 0 0 10px;
    }

    .textArea__input {
        padding: 20px 12px;
        min-height: 139px;
        height: 65px;
        font-weight: 600;
        color: var(--dark);
        border: 1px solid var(--input-gray);

        &:active,
        &:focus {
            border: 1px solid var(--dark-btn);
            outline: none;
        }

        &::placeholder {
            color: #CACBCC;
        }
    }
}