.articleDate__info {
    display: flex;
    align-items: center;
    margin: 33px 0 0;

    .articleDate__service {
        font-weight: 800;
    }

    .articleDate__dot {
        margin: 0 14px;
        display: inline-block;
        border-radius: 50%;
        width: 4px;
        height: 4px;
    }

    .articleDate__date {
        font-weight: 600;
    }

    &.article_in_header {
        z-index: 1;
        color: white;
        font-size: 16px;
        line-height: 22px;

        .articleDate__service {
            position: relative;
            z-index: 2;

            &::before {
                position: absolute;
                top: 50%;
                transform: translate(-25%, -50%);
                left: 0;
                width: 35px;
                height: 35px;
                content: '';
                background-color: var(--blue);
                z-index: -1;
            }
        }

        .articleDate__dot {
            background-color: white;
        }

        // .articleDate__date {
        //     // line-height: 25px;
        // }
    }

    &.article_in_card {
        font-size: 18px;
        line-height: 25px;

        .articleDate__service {
            // line-height: 25px;
            color: var(--blue);
        }

        .articleDate__dot {
            background-color: var(--blue);
        }

        .articleDate__date {
            color: #394149;
        }
    }
}