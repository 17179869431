.ourArticles {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 1119px;
    margin: 0;
    padding: 0 9.22% 0 8.76%;
    padding-top: 63px;
    // border-bottom: 1px solid #EFF1F3;
    border-bottom: 1px solid var(--gray);
    // color: #12171D;
    color: var(--dark);
    font-family: "Manrope", sans-serif;
    @media only screen and (max-width: 768px) {
        padding-bottom: 3rem;
    }

    .ourArticles__wrap-h1 {
        // width: 68.13%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 24px;
        padding: 0 0 79px;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1.5rem;
        }

        .ourArticles__h1 {
            max-width: 50%;
            // width: min-content;
            margin: 0;
            font-family: "Krona One", sans-serif;
            font-weight: 400;
            font-size: 74px;
            line-height: 92px;
            @media only screen and (max-width: 768px) {
                font-size: 34px;
                line-height: 42px;
            }
        }

        .button {
            margin-right: 16.26%;
            height: 54px;
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .ourArticles__articles {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 4rem;
            > article {
                width: 100%;
            }
        }

        // .ourArticles__wrap-article {
        //     width: 46%;
        //     font-size: 18px;

        //     .ourArticles__article-img {
        //         max-width: 100%;
        //         height: auto;
        //     }
        // }

        // .ourArticles__title {
        //     margin: 24px 0 24px;
        //     font-weight: 500;
        //     font-size: 34px;
        //     line-height: 48px;
        // }

        // .ourArticles__description {
        //     margin: 0;
        //     font-weight: 400;
        //     font-size: 18px;
        //     line-height: 32px;
        // }

        // .ourArticles__wrap-footer {
        //     margin: 37px 0 0;
        //     display: flex;
        //     justify-content: space-between;

        //     .ourArticles__wrap-author {
        //         display: flex;
        //         align-items: center;

        //         .ourArticles__wrap-avatar {
        //             margin: 0 24px 0 0;
        //             position: relative;

        //             img {
        //                 max-width: 100%;
        //                 height: auto;
        //             }

        //             &::after {
        //                 position: absolute;
        //                 top: 50%;
        //                 left: 50%;
        //                 transform: translate(0, -50%);
        //                 content: '';
        //                 width: 75%;
        //                 height: 63px;
        //                 background-color: var(--blue);
        //                 z-index: -1;
        //                 ;
        //             }
        //         }

        //         .ourArticles__wrap-txt {
        //             display: flex;
        //             flex-direction: column;
        //             justify-content: center;

        //             .ourArticles__name {
        //                 margin: 0 0 3px;
        //                 font-weight: 600;
        //                 font-size: 16px;
        //                 line-height: 22px;
        //             }

        //             .ourArticles__profession {
        //                 margin: 0;
        //                 font-weight: 500;
        //                 font-size: 14px;
        //                 line-height: 19px;
        //                 color: #B0B0B0;
        //             }
        //         }
        //     }
        // }
    }

    .ourArticles__line {
        position: absolute;
        top: 0;
        width: 1px;
        // height: 3499px;
        height: 100%;
        left: 50%;
        background-color: var(--gray);
        z-index: -1;

        .ourArticles__active-line {
            position: absolute;
            top: 14%;
            left: 0;
            height: 0;
            width: 1px;
            background-color: var(--blue);
            transition: var(--transition-for-line);
        }

        .ourArticles__active-line.active {
            height: 109px;
        }
    }
}
